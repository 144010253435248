'use client'
import { useState } from 'react'
import Image from "next/image"
import { Combobox } from "@headlessui/react"
import { RiArrowDropDownLine } from 'react-icons/ri'
import tailwindConfig from '@tailwind.config'
import { FaSearch } from "react-icons/fa";
import { FaBuildingUser, FaBuilding, FaLocationDot, FaTreeCity } from "react-icons/fa6";

const HeroDropdownSearch = ({ onChange, searchList, dictionary }) => {
    const [query, setQuery] = useState('')
    const [selectedSearch, setSelectedSearch] = useState(searchList[0])

    const filteredSearch =
        query === ''
            ? searchList
            : searchList.filter((item) => (
                item.name.toLowerCase().replace(/\s+/g, '').includes(query.toLocaleLowerCase().replace(/\s+/g, '')
                )))

    const selectTextOnClick = (e) => {
        e.target.select();
    };
    return (
        <div className='flex flex-col w-full'>
            <Combobox
                value={selectedSearch?.name}
                onChange={
                    (value) => {
                        setSelectedSearch(value)
                        onChange(value)
                    }

                }
            >
                <div className="w-full rounded-xl">
                    <div className='z-1 md:px-2 md:py-2 rounded-xl relative w-full cursor-default overflow-hidden bg-white text-left text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm '>
                        <Combobox.Input 
                        onClick={selectTextOnClick} 
                        onChange={(event) => {
                            setQuery(event.target.value)
                            onChange(event.target.value)
                        }} 
                        required 
                        placeholder={dictionary.filter?.searchForPlaceholder} 
                        className='w-full rounded-lg border-none py-2 pl-3 pr-10 text-md leading-5 text-maincolor focus:ring-0 focus:outline-none focus-visible:ring-2 focus-visible:ring-slate-300 focus-visible:ring-opacity-75 focus-visible:ring-offset-0' aria-label="BTS/MRT Search Input" onFocus={() => {
                        }} />
                        {/* <Combobox.Button className="absolute inset-y-0 right-0 flex items-center" id="mrt-dropdown" aria-label="BTS/MRT Dropdown Button" >
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                                <RiArrowDropDownLine
                                    color={tailwindConfig.theme.extend.colors.secondary}
                                    size={28}
                                />
                            </span>
                        </Combobox.Button> */}
                    </div>
                    <Combobox.Options
                        as="ul"
                        className='z-[1000] mt-2 left-0 right-0 absolute bg-white h-auto md:max-h-[200px] max-h-[150px] w-full overflow-y-auto shadow-md rounded-xl overflow-hidden py-1'
                    >
                        {query.length > 0 && (
                            <Combobox.Option 
                                key={'default'}
                                value={{ id: null, name: query, subtitle: '', type: 'name' }}
                                className='py-3 px-4 cursor-pointer color-secondary z-50 flex flex-row items-center hover:bg-forth'
                            >
                                <span className='text-secondary'>{dictionary?.filter?.searchFor}: "{query}"</span>
                            </Combobox.Option>
                        )}
                        {filteredSearch.length > 0 && filteredSearch.map((search, index) => (
                            <Combobox.Option
                                key={`${index}-${search.name}`}
                                value={search}
                                className='py-2 px-4 cursor-pointer color-secondary z-50 flex flex-row items-center hover:bg-forth'
                            >
                                {(search.type == '' || search.type == 'name') && (
                                    <span className='flex flex-row justify-between items-center'>
                                        <FaSearch color={tailwindConfig.theme.extend.colors.secondary} />
                                        <span className="ml-2 py-1 text-maincolor">{search.name !== '' ? search.name : 'Search for Anything'}</span>
                                    </span>

                                )}
                                {(search.type == 'district') && (
                                    <span className='flex flex-row justify-between items-center'>
                                        <FaLocationDot color={tailwindConfig.theme.extend.colors.secondary} />
                                        <span className="ml-2 text-maincolor">{search.name} ({dictionary.filter.areaIn}{search.subtitle})</span>
                                    </span>
                                )}
                                {(search.type == 'location') && (
                                    <span className='flex flex-row justify-between items-center'>
                                        <FaTreeCity color={tailwindConfig.theme.extend.colors.secondary} />
                                        <span className="ml-2 text-maincolor">{search.name} ({dictionary.filter.location})</span>
                                    </span>
                                )}
                                {(search.type == 'newdevelopment') && (
                                    <span className='flex flex-row justify-between items-center'>
                                        <FaBuilding color={tailwindConfig.theme.extend.colors.secondary} />
                                        <span className="ml-2 text-maincolor">{search.name} ({dictionary.filter.newDevelopment})</span>
                                    </span>
                                )}
                                {(search.type == 'project') && (
                                    <span className='flex flex-row justify-between items-center'>
                                        <FaBuildingUser color={tailwindConfig.theme.extend.colors.secondary} />
                                        <span className="ml-2 text-maincolor">{search.name} ({dictionary.filter.project})</span>
                                    </span>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                </div>
            </Combobox>
        </div >
    )
}

export default HeroDropdownSearch